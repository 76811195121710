<!-- <div id='popup'>
  <div id="close">Close</div>
</div> -->
<div class="top_section">
  <div class="container">
    <div class="row">
      <div class="grid_5">
        <p class="txt1 marLeft1"><img src="assets/images/icon1.png" alt="" class="img1 no_resize">2443 Fillmore St #380-9091 | San Francisco, CA 94115
        </p>
      </div>
      <div class="grid_2">
        <p class="txt1 marLeft2"><img src="assets/images/icon2.png" alt="" class="img1 no_resize"><a
            href="mailto:info@alphawitch.com" class="link">info&#64;alphawitch.com</a></p>
      </div>
      <!-- <div class="grid_2">
        <p class="txt1">
         Phone number icon removed <img src="assets/images/icon3.png" alt="" class="img1 no_resize">
        </p>
      </div> -->
      <div class="grid_3">
        <ul class="soc_icons">
          <li><a href="https://www.facebook.com/alphawitchpub" target="_blank"><fa-icon [icon]="faFacebook"></fa-icon></a></li>
          <li><a href="https://twitter.com/AlphaWitchPub" target="_blank"><fa-icon [icon]="faTwitter"></fa-icon></a></li>
          <li><a href="https://www.pinterest.com/alphawitchp/" target="_blank"><fa-icon [icon]="faPinterest"></fa-icon></a></li>
          <li><a href="https://www.linkedin.com/in/AlphaWitchPub" target="_blank"><fa-icon [icon]="faLinkedin"></fa-icon></a>
          </li>
          <!-- <li><a href="https://www.pinterest.com/alphawitchp/feed.rss" target="_blank"><i class="fa fa-rss"></i></a>
          </li> -->
          <li><a href="https://blog.alphawitch.com/" target="_blank"><fa-icon [icon]="faNewspaper"></fa-icon></a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!--========================================================
                              HEADER 
    =========================================================-->
<header id="header">
  <div id="stuck_container">
    <div class="container">
      <div class="row">
        <div class="grid_12 txt_cntr">
          <h1><a href="index.html"><img src="assets/images/logo.png" alt="AlphaWitch"></a> </h1>
        </div>
      </div>
      <div class="row">
        <div class="grid_12">
          <nav>
            <ul class="sf-menu">
              <li><a [routerLink]="[ '/home' ]" routerLinkActive="current">Home<strong></strong></a></li>
              <li><a [routerLink]="[ '/about' ]" routerLinkActive="current">About<strong></strong></a>
                <!--									<ul class="submenu">
                      <li><a href="#">History</a></li> 
                      <li><a href="#">News</a>
                        <ul class="submenu2">
                          <li><a href="#">Latest</a></li>
                          <li><a href="#">Archive</a></li>
                        </ul>
                      </li>
                    <li><a href="#">Testimonials</a></li> 
                    </ul> -->
              </li>
              <li><a [routerLink]="[ '/books' ]" routerLinkActive="current">Books<strong></strong></a></li>
              <!--<li><a href="index-2.html">Top sellers<strong></strong></a></li> -->
              <li><a [routerLink]="[ '/authors' ]" routerLinkActive="current">Authors<strong></strong></a></li>
              <!--<li><a href="http://freebies.alphawitch.com/" target="_blank">Freebies!!<strong></strong></a></li> 
                <li><a href="index-5.html" >Blog<strong></strong></a></li>  -->
              <!-- <li><a [routerLink]="[ '/contacts' ]" routerLinkActive="current">Contacts<strong></strong></a></li> -->
              <!--<li><a [routerLink]="[ '/submissions' ]" routerLinkActive="current">Submissions<strong></strong></a></li> -->              
              <li id="last-li"><a href="https://merch.alphawitch.com/" id="merch" target="_blank" title="Get yourself some swag!">Merch<strong></strong></a></li>
              <li id="last-li"><a href="https://shop.alphawitch.com/collections/all" id="shop" target="_blank" title="Books we sell direct">Book Shop<strong></strong></a></li>
              <li ><a [routerLink]="[ '/mail' ]" routerLinkActive="current" id="mail">Join our Mailing List<strong></strong></a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>