import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LayoutComponent } from './Layout/Layout/Layout.component';

const routes: Routes = [ { path : '', component : LayoutComponent ,
                                children: [ {path: '', loadChildren: () => import('./Pages/Pages.module').then(m => m.PagesModule) }
]},
{ path: '**', redirectTo: 'home' } ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
